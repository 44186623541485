

















import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({
  components: {
    SimpleContactForm: () =>
      import(
        /* webpackChunkName: 'bottom-contact-section-simple-contact-form' */ "@/components/SimpleContactForm.vue"
      )
  }
})
export default class BottomContactSection extends Vue {}
